<template>
	<section class="invoice-preview-wrapper">
		<b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
			<b-row>
				<b-col cols="12" md="8">
					<b-card no-body class="invoice-actions">
						<b-card-body>
							<b-row>
								<b-col cols="12" md="2">
									<label for="date_start">Fecha Inicio</label>
									<flat-pickr id="date_start" v-model="filter.fechaInicio"
										class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
										placeholder="DD/MM/YYYY" />
								</b-col>
								<b-col cols="12" md="2">
									<label for="date_start">Fecha Fin</label>
									<flat-pickr id="date_start" v-model="filter.fechaFin"
										class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
										placeholder="DD/MM/YYYY" />
								</b-col>

								<b-col cols="12" md="4">
									<label for="date_start">Tipo Bitacora</label>
									<v-select v-model="filter.tipo" :options="tipoBitacora" class="select-size-sm"
										:reduce="option => option.value" label="text"
										placeholder="Seleccione un tipo de bitacora"
										v-ripple.400="'rgba(255, 255, 255, 0.15)'" :clearable="false" />
								</b-col>

								<b-col cols="12" md="4">
									<div class="demo-inline-spacing">
										<b-button size="sm" @click="getRoutingLogs" variant="primary"
											v-ripple.400="'rgba(255, 255, 255, 0.15)'">
											<feather-icon icon="SearchIcon" />
											Buscar
										</b-button>

										<b-button size="sm" v-if="items.length > 0" @click="printReport"
											variant="secondary" v-ripple.400="'rgba(255, 255, 255, 0.15)'">
											<feather-icon icon="PrinterIcon" />
											Imprimir
										</b-button>
									</div>
								</b-col>
							</b-row>
						</b-card-body>
					</b-card>
				</b-col>
			</b-row>

			<b-row>
				<b-col cols="12">
					<b-card no-body class="mb-0">
						<div class="position-relative b-table-responsive mb-0 text-small small table-responsive"
                        	:class="`${items.length > 10 ? 'b-table-sticky-header' : ''}`">
                            <table ref="articlesTable" class="table b-table table-sm b-table-no-border-collapse text-small small b-table-small b-table-selectable b-table-select-single">
                                <thead>
									<tr>
										<th style="font-size: 10px!important;">Generado Por</th>
										<th style="font-size: 10px!important;">Tipo</th>
										<th style="font-size: 10px!important;">Documento</th>
										<th style="font-size: 10px!important;">Descripción</th>
										<th style="font-size: 10px!important;">Fecha Alta</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, index) in items" :key="index">
										<td style="font-size: 10px!important;">{{ item.nombre_Usuario }}</td>
										<td style="font-size: 10px!important;">{{ item.tipo }}</td>
										<td style="font-size: 10px!important;">{{ item.folio }}</td>
										<td style="font-size: 10px!important;">{{ item.descripcion }}</td>
										<td style="font-size: 10px!important;" class="text-capitalize">{{ formatDate(item.fecha_Alta) }}</td>
									</tr>
								</tbody>
								<!-- <tbody v-else>
									<tr>
										<td colspan="5" class="text-center">
											<h6 class="mt-2">No se encontraron registros</h6>
										</td>
									</tr>
								</tbody> -->
							</table>
						</div>
					</b-card>
				</b-col>
			</b-row>

		</b-overlay>
	</section>
</template>

<script>
import {
	BCard,
	BCardHeader,
	BCardText,
	BCardBody,
	BTable,
	BTableLite,
	BOverlay,
	BSpinner,
	BRow,
	BCol,
	BFormInput,
	BButton,
	BInputGroup,
	BInputGroupPrepend,

} from 'bootstrap-vue'
import { ref, onMounted, computed, watch, reactive, defineEmits, nextTick } from "@vue/composition-api";
import store from '@/store'
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import * as helper from '@/libs/helpers';
import vSelect from "vue-select";
import Ripple from 'vue-ripple-directive'

import moment from 'moment';

import BitacorasProvider from '@/providers/WebBitacoras'
const BitacorasResource = new BitacorasProvider()

export default {
	name: "RoutingLogs",
	components: {
		BCard,
		BCardBody,
		BCardHeader,
		BCardText,
		BTable,
		BTableLite,
		BOverlay,
		BSpinner,
		BRow,
		BCol,
		BButton,
		BFormInput,
		BInputGroup,
		BInputGroupPrepend,

		//
		flatPickr,
		vSelect,
	},
	directives: {
		Ripple,
	},
	data() {
		return {

		}
	},
	created() {

	},
	methods: {

	},
	setup() {

		const loading = ref(false)
		const search = ref(null)

		const filter = ref({
			fechaInicio: moment().startOf('month').format('YYYY-MM-DD'),
			fechaFin: moment().format('YYYY-MM-DD'),
			tipo: "TODOS",
		})

		// Column Definitions: Defines the columns to be displayed.
		const columns = ref([]);
		const items = ref([])
		const tipoBitacora = ref([
			{ value: 'TODOS', text: 'TODOS' },
			{ value: 'CAMBIO ALMACEN LOGISTICA', text: 'CAMBIO ALMACEN LOGISTICA' },
			{ value: 'CAMBIO CHOFER', text: 'CAMBIO CHOFER' },
			{ value: 'CERRADO CON PEDIENTE', text: 'CERRADO CON PEDIENTE' },
			{ value: 'CERRADO CON SALIDAS', text: 'CERRADO CON SALIDAS' },
			{ value: 'DEVOLUCION', text: 'DEVOLUCION' },
			{ value: 'DOCUMENTO RECIBIDO', text: 'DOCUMENTO RECIBIDO' },
			{ value: 'ENTREGA PROGRAMADA', text: 'ENTREGA PROGRAMADA' },
			{ value: 'IMPRESION HOJA CONTROL', text: 'IMPRESION HOJA CONTROL' },
			{ value: 'REAPERTURA FACTURA', text: 'REAPERTURA FACTURA' },
			{ value: 'SALIDA CON RUTA CERRADA', text: 'SALIDA CON RUTA CERRADA' }
		]);


		const getRoutingLogs = async () => {
			items.value = []
			try {
				loading.value = true
				const { data } = await BitacorasResource.index(filter.value)
				loading.value = false
				items.value = _.orderBy(data.data, ['fecha_Alta'], ['desc'])

			} catch (error) {
				console.error(error)
				loading.value = false
			}
		}

		const printReport = () => {
			window.print()
		}

		// Computed: Filter Prices



		return {
			search,
			loading,
			items,
			filter,
			tipoBitacora,

			//Methods
			getRoutingLogs,
			printReport,
		};
	}
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss">
@media print {

	// Global Styles
	body {
		background-color: transparent !important;
		font-size: 8pt !important;
	}

	nav.header-navbar {
		display: none;
	}

	.main-menu {
		display: none;
	}

	.header-navbar-shadow {
		display: none !important;
	}

	.content.app-content {
		margin-left: 0;
		padding-top: 2rem !important;
	}

	footer.footer {
		display: none;
	}

	.card {
		background-color: transparent;
		box-shadow: none;
	}

	.customizer-toggle {
		display: none !important;
	}

	// Invoice Specific Styles
	.invoice-preview-wrapper {



		// Action Right Col
		.invoice-actions {
			display: none;
		}
	}
}
</style>


<style>
.ag-theme-quartz.large,
.ag-theme-quartz-dark.large {
	--ag-grid-size: 10px;
	--ag-list-item-height: 40px;
	--ag-font-size: 32px;
}

.ag-theme-quartz.compact,
.ag-theme-quartz-dark.compact {
	--ag-grid-size: 3px;
	--ag-list-item-height: 20px;
	--ag-font-size: 10px;
}

.flatpickr-small .flatpickr-input {
	/*font-size: 8px!important; /* Ajusta el tamaño del texto del input */
	padding: 5px;
	/* Ajusta el padding del input */
	/*width: 120px; /* Ajusta el ancho del input */
}

.flatpickr-input {
	/*width: 150px!important; /* Ajusta el ancho del input */
	height: 30px !important;
	/* Ajusta la altura del input */
	/*font-size: 7px!important; /* Ajusta el tamaño del texto del input */
}
</style>

<style>
.b-table-sticky-header {
    overflow-y: auto;
    max-height: calc(100vh - 120px)!important;
}
</style>